import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchSingleProduct(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/products/${data.id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchProducts(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/products', { params: data })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addProduct(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/products', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateProduct(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/api/products/${data.id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteProduct(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/products/${data.id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
